import { useContext, useState } from "react";
import { createContext } from "react";
import Cookies from "js-cookie";

export const UserContext = createContext({
    loading: false,
    isLogged: false,
    changeLoading: (change: boolean) => {},
    login: () => {},
    logout: () => {},
});

export const UserContextProvider = (props: any) => {
    const [loading, setLoading] = useState(true);
    const [isLogged, setIsLogged] = useState(false);

    const changeLoading = (change: boolean) => {
        setLoading(change);
    }

    const login = () => {
        setIsLogged(true);
    }

    const logout = () => {
        Cookies.remove("AuthToken");
        setIsLogged(false);
    }

    return <>
        <UserContext.Provider value={{
            loading,
            isLogged,
            changeLoading,
            login,
            logout,
        }}>
            {props.children}
        </UserContext.Provider>
    </>

}

export const useUserContext = () => {
    return useContext(UserContext);
}