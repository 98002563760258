import { Box, Button, Center, FormControl, FormErrorMessage, FormLabel, Input, Spinner, Stack } from "@chakra-ui/react"
import axios from "axios"
import { useEffect, useState } from "react"
import { Link, Navigate, useNavigate } from "react-router-dom"
import Cookies from "js-cookie";
import { useUserContext } from "./UserContext";


type Warning = {
    show: boolean,
    text: string
}

type Response = {
    success: boolean,
    code: number,
    token: string
}

export const Login = () => {

    const { login, isLogged, loading } = useUserContext();

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [emailWarning, setEmailWarning] = useState<Warning>({show: false, text: ""});
    const [passwordWarning, setPasswordWarning] = useState<Warning>({show: false, text: ""});

    const [width, setWidth] = useState(0);

    const loginHandler = () => {
        axios.post("https://prihlasky.yarynator.dev/login", {email, password})
            .then(response => {
                const data: Response = response.data;
                if(data.success) {
                    Cookies.set("AuthToken", data.token);
                    login();
                    navigate("/admin");
                } else {
                    switch(data.code) {
                        case 201:
                            setEmailWarning({show: true, text: "Účet pod tímto e-mailem není registrován!"});
                            setPasswordWarning({show: false, text: ""});
                            break;
                        case 202:
                            setEmailWarning({show: false, text: ""});
                            setPasswordWarning({show: true, text: "Špatné heslo!"});
                            break;
                    }
                }
            });
    }

    useEffect(() => {
        const resizeHandler = () => {
            setWidth(window.innerWidth);
        }

        window.addEventListener("resize", resizeHandler);
        resizeHandler();
    }, []);

    if(loading) {
        return <Spinner />
    }

    if(isLogged) {
        return <Navigate to="/admin" />
    }

    return <Stack spacing="3">
        <Link to="/"><Button position="absolute" left="1rem" top="1rem">Zpět na formulář</Button></Link>
        <FormControl isRequired isInvalid={emailWarning.show}>
            <FormLabel>E-mail:</FormLabel>
            <Input w={width < 500 ? "90vw" : "400px"} backgroundColor="white" type="email" placeholder="example@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
            <FormErrorMessage>{emailWarning.text}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={passwordWarning.show}>
            <FormLabel>Heslo:</FormLabel>
            <Input w={width < 500 ? "90vw" : "400px"} backgroundColor="white" type="password" placeholder="Heslo" value={password} onChange={(e) => setPassword(e.target.value)} />
            <FormErrorMessage>{passwordWarning.text}</FormErrorMessage>
        </FormControl>
        <Center>
            <Button onClick={loginHandler}>Přihlásit se</Button>
        </Center>
    </Stack>
}