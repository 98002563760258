import { Center, Flex, useFocusEffect } from "@chakra-ui/react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Footer } from "./Footer"
import { Form } from "./Form"
import { Login } from "./Login"
import { Menu } from "./Menu"
import { Admin } from "./Admin"
import { useEffect } from "react"
import Cookies from "js-cookie"
import axios from "axios"
import { useUserContext } from "./UserContext"
import { Register } from "./Register"

export const Manager = () => {

    const { login, changeLoading } = useUserContext();

    useEffect(() => {
        const checkToken = async() => {
            const token = Cookies.get("AuthToken");
            if(token) {
                const response = await axios.post("https://prihlasky.yarynator.dev/checkToken", { token });

                if(response.data.success) {
                    login();
                }
            }
            changeLoading(false);
        }

        checkToken();
    }, [])

    return <Flex minH="100vh" flexDir="column" justifyContent="space-between" backgroundColor="blackAlpha.200">
        <Menu />
        <BrowserRouter>
            <Center>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/admin" element={<Admin />} />
                    <Route path="/*" element={<Form />} />
                </Routes>
            </Center>
        </BrowserRouter>
        <Footer />
    </Flex>
}