import { ChakraProvider } from '@chakra-ui/react';
import { Manager } from './Components/Manager';
import "./Fonts.css";
import { UserContextProvider } from './Components/UserContext';

function App() {
    return <ChakraProvider>
        <UserContextProvider>
            <Manager />
        </UserContextProvider>
    </ChakraProvider>
}

export default App;
