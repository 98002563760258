import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Center, Flex, Grid, GridItem, Link as ChakraLink, Spinner, Tab, TabList, TabPanel, TabPanels, Table, Tabs, Tbody, Td, Text, Th, Tr, useDisclosure } from "@chakra-ui/react"
import { useUserContext } from "./UserContext"
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Progress } from "antd";
import { ArrowLeftIcon, ArrowRightIcon, DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import React from "react";
import CsvDownloadButton from "react-json-to-csv";

type Registration = {
    id: string,
    fee: number,
    email: string,
    name: string,
    birthNum: string,
    address: string,
    health: string,
    swimmer: boolean,
    parent1Name: string,
    parent1Relation: string,
    parent1Tel: string,
    parent2Name: string,
    parent2Relation: string,
    parent2Tel: string,
    timestamp: Date
}

export const Admin = () => {

    const { loading, isLogged, logout } = useUserContext();

    const navigate = useNavigate();

    const [registrations, setRegistrations] = useState<Array<Registration>>([]);
    const [registrationIndex, setRegistrationIndex] = useState(0);

    const [swimmerCount, setSwimmerCount] = useState(0);
    const [fullPriceCount, setFullPriceCount] = useState(0);

    const [width, setWidth] = useState(0);

    const [participant, setParticipant] = useState("");
    const [participantId, setParticipantId] = useState("");
    const [deleteLoad, setDeleteLoad] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef<any>()

    const [csvData, setCsvData] = useState<Array<any>>([]);

    const getData = async () => {
        const token = Cookies.get("AuthToken");

        if(token){
            const response = await axios.post("https://prihlasky.yarynator.dev/data", {token})
            const data: Array<Registration> = response.data.data;

            data.sort((a, b) => Number(new Date(b.timestamp)) - Number(new Date(a.timestamp)));

            let sw = 0;
            let price = 0;
            data.forEach(reg => {
                if(reg.swimmer) {
                    sw++;
                }
                if(reg.fee == 3500) {
                    price++;
                }
            });
            
            setFullPriceCount(price);
            setSwimmerCount(sw);
            setRegistrations(data);
        }
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        const resizeHandler = () => {
            setWidth(window.innerWidth);
        }

        window.addEventListener("resize", resizeHandler);
        resizeHandler();
    }, []);

    useEffect(() => {
        const data: Array<any> = [];

        registrations.forEach(registration => {
            data.push({
                id: registration.id,
                poplatek: registration.fee,
                ucastnik: registration.name,
                kontaktni_email: registration.email,
                rodne_cislo: registration.birthNum,
                bydliste: registration.address,
                zdravi: registration.health,
                plavec: registration.swimmer,
                pribuzny1_jmeno: registration.parent1Name,
                pribuzny1_vztah: registration.parent1Relation,
                pribuzny1_telefon: registration.parent1Tel,
                pribuzny2_jmeno: registration.parent2Name,
                pribuzny2_vztah: registration.parent2Relation,
                pribuzny2_telefon: registration.parent2Tel,
                casova_znacka: new Date(registration.timestamp)
            })
        })

        setCsvData(data);
    }, [registrations]);

    const deleteHandle = async () => {
        const token = Cookies.get("AuthToken");
        if(token){
            const response = await axios.post("https://prihlasky.yarynator.dev/delete", {token, id: participantId});
            console.log(response.data);

            if(response.data.success) {
                await getData();
            }

            setDeleteLoad(false);
            onClose();
        } else {
            setDeleteLoad(false);
            onClose();
        }
    }

    if(loading) {
        return <Spinner />
    }

    if(!isLogged) {
        return <Navigate to="/login" />
    }

    return <Box>
        <Link to="/"><Button position={width < 500 ? "relative" : "absolute"} left={width < 500 ? "" : "1rem"} top={width < 500 ? "" : "1rem"}>Zpět na formulář</Button></Link>
        <Button ml={width < 195 ? "" : "1rem"} position={width < 500 ? "relative" : "absolute"} right={width < 500 ? "" : "1rem"} top={width < 500 ? "" : "1rem"} onClick={() => {
            logout();
            navigate("/");
        }}>Odhlásit se</Button>

        <Tabs w={width < 500 ? "90vw" : "60vw"} mb="1rem">
        <TabList>
            <Tab>Souhrn</Tab>
            <Tab >Individuální</Tab>
        </TabList>

        <TabPanels>
            <TabPanel>
                {
                    registrations.length === 0 ? "Zatím nebyly poslány žádné přihlášky" :
                
                    <Box>
                    <CsvDownloadButton data={csvData} filename="tabor2023.csv" ><DownloadIcon /> Stáhnout data</CsvDownloadButton>
                    <Text fontWeight="bold" mt="1rem">Jméno</Text>
                    {
                        registrations.map((registration) => <Box key={`name${registration.id}`}>
                            {
                                <Text>{registration.name}</Text>
                            }
                        </Box>)
                    }

                    <Text mt="1rem" fontWeight="bold">Cena</Text>
                    <Flex justifyContent="space-between" flexDir={width < 500 ? "column" : "row"}>
                        <Box>
                            {
                                registrations.map((registration) => <Box key={`fee${registration.id}`}>
                                    {
                                        <Text>{registration.fee}</Text>
                                    }
                                </Box>)
                            }
                        </Box>
                        <Center>
                            {
                                registrations.length > 2 ? <Flex alignItems="center"><Text mr="1rem">Poměr plných cen:</Text><Progress type="circle" percent={Math.round((fullPriceCount / registrations.length) * 100)} size={50} /></Flex> : registrations.length > 5 ? <Flex alignItems="center"><Text mr="1rem">Poměr plných cen:</Text><Progress type="circle" percent={Math.round((fullPriceCount / registrations.length) * 100)} size={80} /></Flex> : ""
                            } 
                        </Center>
                    </Flex>

                    <Text mt="1rem" fontWeight="bold">Kontaktní e-mail</Text>
                    {
                        registrations.map((registration) => <Box key={`email${registration.id}`}>
                            {
                                <Text>{registration.email}</Text>
                            }
                        </Box>)
                    }

                    <Text mt="1rem" fontWeight="bold">Rodné číslo</Text>
                    {
                        registrations.map((registration) => <Box key={`birthNum${registration.id}`}>
                            {
                                <Text>{registration.birthNum}</Text>
                            }
                        </Box>)
                    }

                    <Text mt="1rem" fontWeight="bold">Bydliště</Text>
                    {
                        registrations.map((registration) => <Box key={`address${registration.id}`}>
                            {
                                <Text>{registration.address}</Text>
                            }
                        </Box>)
                    }

                    <Text mt="1rem" fontWeight="bold">Informace o zdraví</Text>
                    {
                        registrations.map((registration) => <Box key={`health${registration.id}`}>
                            {
                                <Text>{registration.health}</Text>
                            }
                        </Box>)
                    }

                    <Text mt="1rem" fontWeight="bold">Plavec/Neplavec</Text>
                    <Flex justifyContent="space-between">
                        <Box>
                            {
                                registrations.map((registration) => <Box key={`swimmer${registration.id}`}>
                                    {
                                        <Text>{registration.swimmer ? "Plavec" : "Neplavec"}</Text>
                                    }
                                </Box>)
                            }
                        </Box>
                        <Center>
                            {
                                registrations.length > 2 ? <Flex alignItems="center"><Text mr="1rem">Plavců:</Text><Progress type="circle" percent={Math.round((swimmerCount / registrations.length) * 100)} size={50} /></Flex> : registrations.length > 5 ? <Flex alignItems="center"><Text mr="1rem">Plavců:</Text><Progress type="circle" percent={Math.round((swimmerCount / registrations.length) * 100)} size={80} /></Flex> : ""
                            }
                            
                        </Center>
                    </Flex>

                    <Text mt="1rem" fontWeight="bold">Kontakt na 1. příbuzného</Text>
                    {
                        registrations.map((registration) => <Box key={`parent1${registration.id}`}>
                            {
                                <Text>{registration.parent1Name} ({registration.parent1Relation}) - {registration.parent1Tel}</Text>
                            }
                        </Box>)
                    }

                    <Text mt="1rem" fontWeight="bold">Kontakt na 2. příbuzného</Text>
                    {
                        registrations.map((registration) => <Box key={`parent2${registration.id}`}>
                            {
                                <Text>{registration.parent2Name} ({registration.parent2Relation}) - {registration.parent2Tel}</Text>
                            }
                        </Box>)
                    }
                    </Box>
                }
            </TabPanel>
            <TabPanel>
                {
                    registrations.length === 0 ? 
                        <Text>Zatím nebyly poslány žádné přihlášky</Text>
                    : <Box>
                        <Grid w="500px" m="auto" templateRows='repeat(1, 1fr)' templateColumns='1fr 3fr 1fr' gap={4} >
                            <GridItem>
                                <Text textAlign="center"><ArrowLeftIcon textAlign="center" onClick={() => {
                                    if(registrationIndex > 0) {
                                        setRegistrationIndex(registrationIndex - 1);
                                    }
                                }} /></Text>
                            </GridItem>
                            <GridItem>
                                <Text textAlign="center">{registrations[registrationIndex].name} ({registrationIndex + 1}/{registrations.length}) <DeleteIcon color="red.700" cursor="pointer" onClick={() => {
                                    setParticipant(registrations[registrationIndex].name);
                                    setParticipantId(registrations[registrationIndex].id);
                                    onOpen();
                                }} /></Text>
                            </GridItem>
                            <GridItem>
                                <Text textAlign="center"><ArrowRightIcon onClick={() => {
                                    if(registrationIndex < registrations.length - 1) {
                                        setRegistrationIndex(registrationIndex + 1);
                                    }
                                }} /></Text>
                            </GridItem>
                    </Grid>

                    <table>
                        <tbody>
                            <tr>
                                <th style={{textAlign: "left", width: "250px"}}>Jméno: </th>
                                <td>{registrations[registrationIndex].name}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: "left"}}>Cena: </th>
                                <td>{registrations[registrationIndex].fee} Kč</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: "left"}}>Kontaktní e-mail: </th>
                                <td>{registrations[registrationIndex].email}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: "left"}}>Rodné číslo: </th>
                                <td>{registrations[registrationIndex].birthNum}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: "left"}}>Bydliště: </th>
                                <td>{registrations[registrationIndex].address}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: "left"}}>Informace o zdraví: </th>
                                <td>{registrations[registrationIndex].health}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: "left"}}>Plavec/Neplavec: </th>
                                <td>{registrations[registrationIndex].swimmer ? "Plavec" : "Neplavec"}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: "left"}}>Kontakt na 1. příbuzného: </th>
                                <td>{registrations[registrationIndex].parent1Name} ({registrations[registrationIndex].parent1Relation}) - {registrations[registrationIndex].parent1Tel}</td>
                            </tr>
                            <tr>
                                <th style={{textAlign: "left"}}>Kontakt na 2. příbuzného: </th>
                                <td>{registrations[registrationIndex].parent2Name} ({registrations[registrationIndex].parent2Relation}) - {registrations[registrationIndex].parent2Tel}</td>
                            </tr>
                        </tbody>
                    </table>

                    </Box>
                }
            </TabPanel>
        </TabPanels>
        </Tabs>

        <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Smazat přihlášku
            </AlertDialogHeader>

            <AlertDialogBody>
              Tato akce vymaže aktuální přihlášku pro účastníka <Text as="span" fontWeight="bold">{participant}</Text>.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Zrušit
              </Button>
              <Button colorScheme='red' onClick={() => {
                if(!deleteLoad)
                {
                    deleteHandle();
                    setDeleteLoad(true);
                }
              }} ml={3}>
                {deleteLoad && <Spinner size="sm" mr=".5rem" />}Smazat
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <ChakraLink position="absolute" right="1rem" bottom="1rem" textDecor="underline" _hover={{textDecor: "none"}}><Link to="/register">Registrovat nového admina</Link></ChakraLink>

    </Box>
}