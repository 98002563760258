import { Box, Button, Center, Flex, FormControl, FormErrorMessage, FormLabel, Input, Spinner, Stack, Text, useToast, Link as ChakraLink } from "@chakra-ui/react"
import { useUserContext } from "./UserContext"
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import axios from "axios";
import Cookies from "js-cookie";

type Warning = {
    show: boolean,
    text: string
}

export const Register = () => {

    const { loading, isLogged, logout } = useUserContext();

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordAgain, setPasswordAgain] = useState("")

    const [emailIsValid, setEmailIsValid] = useState(false);
    const [emailWarning, setEmailWarning] = useState<Warning>({show: false, text: ""})
    const [passwordWarning, setPasswordWarning] = useState<Warning>({show: false, text: ""})
    const [passwordAgainWarning, setPasswordAgainWarning] = useState<Warning>({show: false, text: ""})

    const [hasTwelveChars, setHasTwelveChars] = useState(false);
    const [hasSmallAndBig, setHasSmallAndBig] = useState(false);
    const [hasNumber, setHasNumber] = useState(false);
    const [hasSpecialChar, setHasSpecialChar] = useState(false);

    const navigate = useNavigate();
    const toast = useToast();

    const [width, setWidth] = useState(0);

    useEffect(() => {
        if(email.length === 0) {
            setEmailWarning({show: true, text: "E-mail musí být vyplněn"});
            setEmailIsValid(false);
        } else {
            const emailParts = email.split("@");
            if(emailParts.length === 2) {
                if(emailParts[0].length > 0 && emailParts[1].length > 0) {
                    
                    const secondPart = emailParts[1].split('.');
                    if(secondPart.length < 2) {
                        setEmailWarning({show: true, text: "E-mail není validní"});
                        setEmailIsValid(false);
                    } else {
                        if(secondPart[0].length > 0 && secondPart[1].length > 1) {
                            setEmailWarning({show: false, text: ""});
                            setEmailIsValid(true);
                        } else {
                            setEmailWarning({show: true, text: "E-mail není validní"});
                            setEmailIsValid(false);
                        }
                    }
                } else {
                    setEmailWarning({show: true, text: "E-mail není validní"});
                    setEmailIsValid(false);
                }
            } else {
                setEmailWarning({show: true, text: "E-mail není validní"});
                setEmailIsValid(false);
            }
        }
    }, [email]);

    useEffect(() => {
        if(password.length < 12) {
            setHasTwelveChars(false);
        } else {
            setHasTwelveChars(true);
        }

        let hasSm = false;
        let hasBg = false;
        let hasNum = false;
        let hasSpec = false;

        for(let i = 0; i < password.length; i++) {
            const char = password.charCodeAt(i);
            if(char >= 48 && char <= 57) {
                hasNum = true;
            }
            else if(char >= 65 && char <= 90) {
                hasBg = true;
            }
            else if(char >= 97 && char <= 122) {
                hasSm = true;
            } 
            else {
                hasSpec = true;
            }
        }

        if(hasSm && hasBg) {
            setHasSmallAndBig(true);
        } else {
            setHasSmallAndBig(false);
        }

        if(hasNum) {
            setHasNumber(true);
        } else {
            setHasNumber(false);
        }

        if(hasSpec) {
            setHasSpecialChar(true);
        } else {
            setHasSpecialChar(false);
        }

        if(password === passwordAgain) {
            setPasswordAgainWarning({show: false, text: ""});
        } else {
            setPasswordAgainWarning({show: true, text: "Hesla nejsou stejná"});
        }

    }, [password]);

    useEffect(() => {
        if(password === passwordAgain) {
            setPasswordAgainWarning({show: false, text: ""});
        } else {
            setPasswordAgainWarning({show: true, text: "Hesla nejsou stejná"});
        }
    }, [passwordAgain]);

    useEffect(() => {
        const resizeHandler = () => {
            setWidth(window.innerWidth);
        }

        setEmailWarning({show: false, text: ""});
        setPasswordWarning({show: false, text: ""});
        setPasswordAgainWarning({show: false, text: ""});

        window.addEventListener("resize", resizeHandler);
        resizeHandler();
    }, []);

    if(loading) {
        return <Spinner />
    }

    if(!isLogged) {
        return <Navigate to="/login" />
    }

    const loginHandler = async () => {
        if(emailIsValid && password === passwordAgain && password.length >= 12 && hasSmallAndBig && hasNumber && hasSpecialChar) {
            const token = Cookies.get("AuthToken");
            if(token){
                const response = await axios.post("https://prihlasky.yarynator.dev/register", {token, email, password});
                
                if(response.data.success){
                    toast({
                        title: 'Účet vytvořen.',
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                } else {
                    toast({
                        title: 'Účet nebyl vytvořen.',
                        description: "Zkus to později.",
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            }
        }
    }

    return <Box>
        <Link to="/"><Button position={width < 500 ? "relative" : "absolute"} left={width < 500 ? "" : "1rem"} top={width < 500 ? "" : "1rem"}>Zpět na formulář</Button></Link>
        <Button ml={width < 195 ? "" : "1rem"} position={width < 500 ? "relative" : "absolute"} right={width < 500 ? "" : "1rem"} top={width < 500 ? "" : "1rem"} onClick={() => {
            logout();
            navigate("/");
        }}>Odhlásit se</Button>
        
        
        <Stack spacing="3">
            <FormControl isRequired isInvalid={emailWarning.show}>
                <FormLabel>E-mail:</FormLabel>
                <Input w={width < 500 ? "90vw" : "400px"} backgroundColor="white" type="email" placeholder="example@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
                <FormErrorMessage>{emailWarning.text}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={passwordWarning.show}>
                <FormLabel>Heslo:</FormLabel>
                <Input w={width < 500 ? "90vw" : "400px"} backgroundColor="white" type="password" placeholder="Heslo" value={password} onChange={(e) => setPassword(e.target.value)} />
                <FormErrorMessage>{passwordWarning.text}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={passwordAgainWarning.show}>
                <FormLabel>Heslo znovu:</FormLabel>
                <Input w={width < 500 ? "90vw" : "400px"} backgroundColor="white" type="password" placeholder="Heslo" value={passwordAgain} onChange={(e) => setPasswordAgain(e.target.value)} />
                <FormErrorMessage>{passwordAgainWarning.text}</FormErrorMessage>
            </FormControl>

            <Flex alignItems="center">
                <Text mr=".5rem" color={hasTwelveChars ? "green.700" : "red.700"}>{hasTwelveChars ? <CheckIcon /> : <CloseIcon fontSize="xx-small" />}</Text>
                <Text ml=".5rem" color={hasTwelveChars ? "green.700" : "red.700"}>Minimálně 12 znaků</Text>
            </Flex>
            <Flex alignItems="center">
                <Text mr=".5rem" color={hasSmallAndBig ? "green.700" : "red.700"}>{hasSmallAndBig ? <CheckIcon /> : <CloseIcon fontSize="xx-small" />}</Text>
                <Text ml=".5rem" color={hasSmallAndBig ? "green.700" : "red.700"}>Obsahuje malá i velká písmena</Text>
            </Flex>
            <Flex alignItems="center">
                <Text mr=".5rem" color={hasNumber ? "green.700" : "red.700"}>{hasNumber ? <CheckIcon /> : <CloseIcon fontSize="xx-small" />}</Text>
                <Text ml=".5rem" color={hasNumber ? "green.700" : "red.700"}>Obsahuje čísla</Text>
            </Flex>
            <Flex alignItems="center">
                <Text mr=".5rem" color={hasSpecialChar ? "green.700" : "red.700"}>{hasSpecialChar ? <CheckIcon /> : <CloseIcon fontSize="xx-small" />}</Text>
                <Text ml=".5rem" color={hasSpecialChar ? "green.700" : "red.700"}>Obsahuje speciální znak</Text>
            </Flex>

            <Center>
                <Button onClick={loginHandler}>Registrovat novou osobu</Button>
            </Center>
        </Stack>

        <ChakraLink position="absolute" right="1rem" bottom="1rem" textDecor="underline" _hover={{textDecor: "none"}}><Link to="/admin">Admin</Link></ChakraLink>
    </Box>
}