import { Box, Button, Center, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Heading, HStack, Input, Link, Radio, RadioGroup, Stack, Text, useToast } from "@chakra-ui/react"
import axios from "axios"
import { useEffect, useState } from "react"
import { Link as RouteLink } from "react-router-dom";

type Warning = {
    show: boolean,
    text: string
}

type Response = {
    success: boolean,
    codes: Array<number>
}

export const Form = () => {

    const toast = useToast();

    const [fee, setFee] = useState("3500");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [birthNum, setBirthNum] = useState("");
    const [address, setAddress] = useState("");
    const [health, setHealth] = useState("");
    const [swimmer, setSwimmer] = useState("");

    const [parent1Name, setParent1Name] = useState("");
    const [parent1Relation, setParent1Relation] = useState("");
    const [parent1Tel, setParent1Tel] = useState("");
    const [parent2Name, setParent2Name] = useState("");
    const [parent2Relation, setParent2Relation] = useState("");
    const [parent2Tel, setParent2Tel] = useState("");

    const [question1, setQuestion1] = useState(false);
    const [question2, setQuestion2] = useState(false);
    const [question3, setQuestion3] = useState(false);

    const [emailWarning, setEmailWarning] = useState<Warning>({show: false, text: ""});
    const [nameWarning, setNameWarning] = useState<Warning>({show: false, text: ""});
    const [birthNumWarning, setBirthNumWarning] = useState<Warning>({show: false, text: ""});
    const [addressWarning, setAddressWarning] = useState<Warning>({show: false, text: ""});
    const [swimmerWarning, setSwimmerWarning] = useState<Warning>({show: false, text: ""});
    const [parent1NameWarning, setParent1NameWarning] = useState<Warning>({show: false, text: ""});
    const [parent1RelationWarning, setParent1RelationWarning] = useState<Warning>({show: false, text: ""});
    const [parent1TelWarning, setParent1TelWarning] = useState<Warning>({show: false, text: ""});
    const [parent2NameWarning, setParent2NameWarning] = useState<Warning>({show: false, text: ""});
    const [parent2RelationWarning, setParent2RelationWarning] = useState<Warning>({show: false, text: ""});
    const [parent2TelWarning, setParent2TelWarning] = useState<Warning>({show: false, text: ""});
    const [question1Warning, setQuestion1Warning] = useState<Warning>({show: false, text: ""});
    const [question2Warning, setQuestion2Warning] = useState<Warning>({show: false, text: ""});
    const [question3Warning, setQuestion3Warning] = useState<Warning>({show: false, text: ""});

    const [validitySwitcher, setValiditySwitcher] = useState(false);
    const [sent, setSent] = useState(false);

    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isNameValid, setIsNameValid] = useState(false);
    const [isBirthNumValid, setIsBirthNumValid] = useState(false);
    const [isAddressValid, setIsAddressValid] = useState(false);
    const [isSwimmerValid, setIsSwimmerValid] = useState(false);
    const [isParent1NameValid, setIsParent1NameValid] = useState(false);
    const [isParent1RelationValid, setIsParent1RelationValid] = useState(false);
    const [isParent1TelValid, setIsParent1TelValid] = useState(false);
    const [isParent2NameValid, setIsParent2NameValid] = useState(false);
    const [isParent2RelationValid, setIsParent2RelationValid] = useState(false);
    const [isParent2TelValid, setIsParent2TelValid] = useState(false);
    const [isQuestion1Valid, setIsQuestion1Valid] = useState(false);
    const [isQuestion2Valid, setIsQuestion2Valid] = useState(false);
    const [isQuestion3Valid, setIsQuestion3Valid] = useState(false);

    
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if(email.length === 0) {
            setEmailWarning({show: true, text: "E-mail musí být vyplněn"});
            setIsEmailValid(false);
        } else {
            const emailParts = email.split("@");
            if(emailParts.length === 2) {
                if(emailParts[0].length > 0 && emailParts[1].length > 0) {
                    
                    const secondPart = emailParts[1].split('.');
                    if(secondPart.length < 2) {
                        setEmailWarning({show: true, text: "E-mail není validní"});
                        setIsEmailValid(false);
                    } else {
                        if(secondPart[0].length > 0 && secondPart[1].length > 1) {
                            setEmailWarning({show: false, text: ""});
                            setIsEmailValid(true);
                        } else {
                            setEmailWarning({show: true, text: "E-mail není validní"});
                            setIsEmailValid(false);
                        }
                    }
                } else {
                    setEmailWarning({show: true, text: "E-mail není validní"});
                    setIsEmailValid(false);
                }
            } else {
                setEmailWarning({show: true, text: "E-mail není validní"});
                setIsEmailValid(false);
            }
        }
    }, [email, validitySwitcher]);

    useEffect(() => {
        if(name.length === 0) {
            setNameWarning({show: true, text: "Jméno musí být vyplněno"});
            setIsNameValid(false);
        } else {
            setNameWarning({show: false, text: ""});
            setIsNameValid(true);
        }
    }, [name, validitySwitcher]);

    useEffect(() => {
        if(birthNum.length === 0) {
            setBirthNumWarning({show: true, text: "Rodné číslo musí být vyplněno"});
            setIsBirthNumValid(false);
        } else {
            setBirthNumWarning({show: false, text: ""});
            setIsBirthNumValid(true);
        }
    }, [birthNum, validitySwitcher]);

    useEffect(() => {
        if(address.length === 0) {
            setAddressWarning({show: true, text: "Bydliště musí být vyplněno"});
            setIsAddressValid(false);
        } else {
            setAddressWarning({show: false, text: ""});
            setIsAddressValid(true);
        }
    }, [address, validitySwitcher]);

    useEffect(() => {
        if(parent1Name.length === 0) {
            setParent1NameWarning({show: true, text: "Jméno kontaktu musí být vyplněno"});
            setIsParent1NameValid(false);
        } else {
            setParent1NameWarning({show: false, text: ""});
            setIsParent1NameValid(true);
        }
    }, [parent1Name, validitySwitcher]);

    useEffect(() => {
        if(parent1Relation.length === 0) {
            setParent1RelationWarning({show: true, text: "Vztah k dítěti musí být vyplněn"});
            setIsParent1RelationValid(false);
        } else {
            setParent1RelationWarning({show: false, text: ""});
            setIsParent1RelationValid(true);
        }
    }, [parent1Relation, validitySwitcher]);

    useEffect(() => {
        if(parent1Tel.length === 0) {
            setParent1TelWarning({show: true, text: "Telefon musí být vyplněn"});
            setIsParent1TelValid(false);
        } else {
            setParent1TelWarning({show: false, text: ""});
            setIsParent1TelValid(true);
        }
    }, [parent1Tel, validitySwitcher]);

    useEffect(() => {
        if(parent2Name.length === 0) {
            setParent2NameWarning({show: true, text: "Jméno kontaktu musí být vyplněno"});
            setIsParent2NameValid(false);
        } else {
            setParent2NameWarning({show: false, text: ""});
            setIsParent2NameValid(true);
        }
    }, [parent2Name, validitySwitcher]);

    useEffect(() => {
        if(parent2Relation.length === 0) {
            setParent2RelationWarning({show: true, text: "Vztah k dítěti musí být vyplněn"});
            setIsParent2RelationValid(false);
        } else {
            setParent2RelationWarning({show: false, text: ""});
            setIsParent2RelationValid(true);
        }
    }, [parent2Relation, validitySwitcher]);

    useEffect(() => {
        if(parent2Tel.length === 0) {
            setParent2TelWarning({show: true, text: "Telefon musí být vyplněn"});
            setIsParent2TelValid(false);
        } else {
            setParent2TelWarning({show: false, text: ""});
            setIsParent2TelValid(true);
        }
    }, [parent2Tel, validitySwitcher]);

    useEffect(() => {
        if(!question1) {
            setQuestion1Warning({show: true, text: "Musí být potvrzeno"});
            setIsQuestion1Valid(false);
        } else {
            setQuestion1Warning({show: false, text: ""});
            setIsQuestion1Valid(true);
        }
    }, [question1, validitySwitcher]);

    useEffect(() => {
        if(!question2) {
            setQuestion2Warning({show: true, text: "Musí být potvrzeno"});
            setIsQuestion2Valid(false);
        } else {
            setQuestion2Warning({show: false, text: ""});
            setIsQuestion2Valid(true);
        }
    }, [question2, validitySwitcher]);

    useEffect(() => {
        if(!question3) {
            setQuestion3Warning({show: true, text: "Musí být potvrzeno"});
            setIsQuestion3Valid(false);
        } else {
            setQuestion3Warning({show: false, text: ""});
            setIsQuestion3Valid(true);
        }
    }, [question3, validitySwitcher]);

    useEffect(() => {
        const resizeHandler = () => {
            setWidth(window.innerWidth)    
        }

        setEmailWarning({show: false, text: ""});
        setNameWarning({show: false, text: ""});
        setBirthNumWarning({show: false, text: ""});
        setAddressWarning({show: false, text: ""});
        setSwimmerWarning({show: false, text: ""});

        setParent1NameWarning({show: false, text: ""});
        setParent1RelationWarning({show: false, text: ""});
        setParent1TelWarning({show: false, text: ""});
        setParent2NameWarning({show: false, text: ""});
        setParent2RelationWarning({show: false, text: ""});
        setParent2TelWarning({show: false, text: ""});

        setQuestion1Warning({show: false, text: ""});
        setQuestion2Warning({show: false, text: ""});
        setQuestion3Warning({show: false, text: ""});

        window.addEventListener("resize", resizeHandler);
        resizeHandler();
    }, [sent]);

    const sendData = async () => {
        if(isEmailValid && isNameValid && isBirthNumValid && isAddressValid && isSwimmerValid && isParent1NameValid && isParent1RelationValid && isParent1TelValid && isParent2NameValid && isParent2RelationValid && isParent2TelValid && isQuestion1Valid && isQuestion2Valid && isQuestion3Valid){
            
            axios.post("https://prihlasky.yarynator.dev/send", {
                fee,
                email,
                name,
                birthNum,
                address,
                health,
                swimmer,
                parent1Name,
                parent1Relation,
                parent1Tel,
                parent2Name,
                parent2Relation,
                parent2Tel,
                question1,
                question2,
                question3
            }).then((response) => {
                const data: Response = response.data;

                if(!data.success) {
                    data.codes.forEach(code => {
                        switch(code) {
                            case 102:
                                setEmailWarning({show: true, text: "E-mail musí být vyplněn!"});
                                break;
                            case 103:
                                setEmailWarning({show: true, text: "E-mail není validní!"});
                                break;
                            case 104:
                                setNameWarning({show: true, text: "Jméno a příjmení účastníka musí být vyplněno!"});
                                break;
                            case 105:
                                setBirthNumWarning({show: true, text: "Rodné číslo účastníka musí být vyplněno!"});
                                break;
                            case 106:
                                setAddressWarning({show: true, text: "Bydliště musí být vyplněno!"});
                                break;
                            case 107:
                                setSwimmerWarning({show: true, text: "Toto pole musí být vyplněno!"});
                                break;
                            case 108:
                                setParent1NameWarning({show: true, text: "Jméno kontaktu musí být vyplněno!"});
                                break;
                            case 109:
                                setParent1RelationWarning({show: true, text: "Vztah k dítěti musí být vyplněn!"});
                                break;
                            case 110:
                                setParent1TelWarning({show: true, text: "Telefon musí být vyplněn!"});
                                break;
                            case 111:
                                setParent2NameWarning({show: true, text: "Jméno kontaktu musí být vyplněno!"});
                                break;
                            case 112:
                                setParent2RelationWarning({show: true, text: "Vztah k dítěti musí být vyplněn!"});
                                break;
                            case 113:
                                setParent2TelWarning({show: true, text: "Telefon musí být vyplněn!"});
                                break;
                            case 114:
                                setQuestion1Warning({show: true, text: "Musí být potvrzeno!"});
                                break;
                            case 115:
                                setQuestion2Warning({show: true, text: "Musí být potvrzeno!"});
                                break;
                            case 116:
                                setQuestion3Warning({show: true, text: "Musí být potvrzeno!"});
                                break;
                        }
                    });

                    toast({
                        title: 'Přihláška nebyla podána.',
                        description: "Zkontrolujte, zda jsou všechny povinné pole vyplněny správně.",
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                      });
                } else {

                    setEmail("");
                    setName("");
                    setBirthNum("");
                    setAddress("");
                    setHealth("");
                    setParent1Name("");
                    setParent1Relation("");
                    setParent1Tel("");
                    setParent2Name("");
                    setParent2Relation("");
                    setParent2Tel("");
                    setQuestion1(false);
                    setQuestion2(false);
                    setQuestion3(false);

                    setEmailWarning({show: false, text: ""});
                    setNameWarning({show: false, text: ""});
                    setBirthNumWarning({show: false, text: ""});
                    setAddressWarning({show: false, text: ""});
                    setSwimmerWarning({show: false, text: ""});
                    setParent1NameWarning({show: false, text: ""});
                    setParent1RelationWarning({show: false, text: ""});
                    setParent1TelWarning({show: false, text: ""});
                    setParent2NameWarning({show: false, text: ""});
                    setParent2RelationWarning({show: false, text: ""});
                    setParent2TelWarning({show: false, text: ""});
                    setQuestion1Warning({show: false, text: ""});
                    setQuestion2Warning({show: false, text: ""});
                    setQuestion3Warning({show: false, text: ""});

                    setSent(!sent);

                    toast({
                        title: 'Přihláška byla podána.',
                        description: `Děkujeme. Účastník ${name} byl úspěšně přihlášen na tábor`,
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    });
                }
            });

        } else {
            if(!swimmer){
                setSwimmerWarning({
                    show: true,
                    text: "Toto pole musí být vyplněno"
                });
            } else {
                setSwimmerWarning({
                    show: false,
                    text: ""
                });
            }

            toast({
                title: 'Přihláška nebyla podána.',
                description: "Zkontrolujte, zda jsou všechny povinné pole vyplněny správně.",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });

            setValiditySwitcher(!validitySwitcher);
        }
    }

    return <Box w={width < 800 ? "90vw" : width < 1100 ? "80vw" : "60vw"}>
        <Heading m="2rem" fontFamily="skautbold, sans-serif">Přihláška Na Tábor 2023</Heading>
        <Text>Více informací o táboru <Link href="https://hlinsko.skauting.cz/tabor/" target="_blank" fontWeight="bold">zde</Link>.</Text>

        <Heading mt="1rem" fontFamily="skautbold, sans-serif" fontSize="large" textAlign="left">Poplatek</Heading>
        <Text>Táborový poplatek pro tento rok činí 3.500,- Kč. Pokud by na tábor jelo více sourozenců do 18 let, platí rodina plnou částku poplatku pouze za 1. dítě, další poplatky jsou sníženy na  3.200,- Kč. Správnou variantu poplatku uhraďte prosím bankovním převodem na účet 233261738/0600 do 12. června 2023. Jako variabilní symbol uveďte rodné číslo dítěte bez lomítka.</Text>
        <Text mt=".5rem">Upozorňujeme, že po neodůvodněném odhlášení dítěte z účasti na táboře, je naší střediskovou radou schváleno účtování storno poplatku v hodnotě 1.000,- Kč (výjimkou by samozřejmě bylo lékařské potvrzení o zdravotní nezpůsobilosti). Tuto elektronickou přihlášku prosím odešlete nejpozději 28. května 2023. Námi vytištěnou přihlášku bude ještě potřeba podepsat zákonným zástupcem dítěte před odjezdem autobusu na akci. Při této příležitosti budou také předány další důležité dokumenty.</Text>

        <FormControl mt="1rem">
            <FormLabel>Typ poplatku: </FormLabel>
            <RadioGroup defaultValue="3500" onChange={(e) => setFee(e)}>
                <Stack>
                    <Radio backgroundColor="white" value="3500">3.500,- Kč (poplatek za 1. dítě účástnící se tábora z rodiny)</Radio>
                    <Radio backgroundColor="white" value="3200">3.200,- Kč (poplatek za dalšího sourozence s věkem do 18 let)</Radio>
                </Stack>
            </RadioGroup>
        </FormControl>

        <FormControl mt="1rem" isRequired isInvalid={emailWarning.show}>
            <FormLabel>E-mail, na kterém Vás můžeme kontaktovat:</FormLabel>
            <Input backgroundColor="white" placeholder="yarynator@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
            <FormErrorMessage>{emailWarning.text}</FormErrorMessage>
        </FormControl>

        <Heading m="1rem 0" fontFamily="skautbold, sans-serif" fontSize="large" textAlign="left">Základní údaje</Heading>

        <Stack>
            <FormControl isRequired isInvalid={nameWarning.show}>
                <FormLabel>Jméno a příjmení účastníka</FormLabel>
                <Input backgroundColor="white" type="text" placeholder="Josef Novák" value={name} onChange={(e) => setName(e.target.value)} />
                <FormErrorMessage>{nameWarning.text}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={birthNumWarning.show}>
                <FormLabel>Rodné číslo</FormLabel>
                <Input backgroundColor="white" type="text" placeholder="012345/6789" value={birthNum} onChange={(e) => setBirthNum(e.target.value)} />
                <FormErrorMessage>{birthNumWarning.text}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={addressWarning.show}>
                <FormLabel>Bydliště</FormLabel>
                <Input backgroundColor="white" type="text" placeholder="Nad Parkem 1317, 539 01 Hlinsko" value={address} onChange={(e) => setAddress(e.target.value)} />
                <FormErrorMessage>{addressWarning.text}</FormErrorMessage>
            </FormControl>
            <FormControl>
                <FormLabel>Důležité informace o zdraví dítěte (alergie, stravovací omezení,...)</FormLabel>
                <Input backgroundColor="white" type="text" placeholder="alergie na ořechy" value={health} onChange={(e) => setHealth(e.target.value)} />
            </FormControl>
            <FormControl isRequired isInvalid={swimmerWarning.show}>
                <FormLabel>Plavec / Neplavec</FormLabel>
                <RadioGroup onChange={(e) => {
                    setSwimmer(e);
                    setIsSwimmerValid(true);
                }}>
                    <HStack>
                    <Radio backgroundColor="white" value="plavec">Plavec</Radio>
                    <Radio backgroundColor="white" value="neplavec">Neplavec</Radio>
                    </HStack>
                </RadioGroup>
                <FormErrorMessage>{swimmerWarning.text}</FormErrorMessage>
            </FormControl>
        </Stack>
        <Stack mt="2rem">
            <Heading fontFamily="skautbold, sans-serif" fontSize="large" textAlign="left">Kontakt na rodiče nebo jiné příbuzné <Text as="span" fontFamily="themix, sans-serif" fontWeight="normal"> - dostupné po dobu tábora (slouží ke kontaktování v případě nemoci apod.)</Text></Heading>
            <FormControl isRequired isInvalid={parent1NameWarning.show}>
                <FormLabel>1. Jméno a příjmení</FormLabel>
                <Input backgroundColor="white" type="text" placeholder="Jana Nováková" value={parent1Name} onChange={(e) => setParent1Name(e.target.value)} />
                <FormErrorMessage>{parent1NameWarning.text}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={parent1RelationWarning.show}>
                <FormLabel>Vztah k dítěti</FormLabel>
                <Input backgroundColor="white" type="text" placeholder="Matka" value={parent1Relation} onChange={(e) => setParent1Relation(e.target.value)} />
                <FormErrorMessage>{parent1RelationWarning.text}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={parent1TelWarning.show}>
                <FormLabel>Telefonní číslo</FormLabel>
                <Input backgroundColor="white" type="text" placeholder="+420 123 456 789" value={parent1Tel} onChange={(e) => setParent1Tel(e.target.value)} />
                <FormErrorMessage>{parent1TelWarning.text}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={parent2NameWarning.show}>
                <FormLabel>2. Jméno a příjmení</FormLabel>
                <Input backgroundColor="white" type="text" placeholder="Josef Novák" value={parent2Name} onChange={(e) => setParent2Name(e.target.value)} />
                <FormErrorMessage>{parent2NameWarning.text}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={parent2RelationWarning.show}>
                <FormLabel>Vztah k dítěti</FormLabel>
                <Input backgroundColor="white" type="text" placeholder="Otec" value={parent2Relation} onChange={(e) => setParent2Relation(e.target.value)} />
                <FormErrorMessage>{parent2RelationWarning.text}</FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={parent2TelWarning.show}>
                <FormLabel>Telefonní číslo</FormLabel>
                <Input backgroundColor="white" type="text" placeholder="123 456 789" value={parent2Tel} onChange={(e) => setParent2Tel(e.target.value)} />
                <FormErrorMessage>{parent2TelWarning.text}</FormErrorMessage>
            </FormControl>
        </Stack>

        

        <Box m=".5rem 0" borderBottom="solid 1px" borderColor="blackAlpha.300" />

        <FormControl isRequired isInvalid={question1Warning.show}>
            <Flex alignItems="start" >
                <Checkbox backgroundColor="white" colorScheme="blue" isChecked={question1} onChange={() => setQuestion1(!question1)}></Checkbox>
                <FormLabel ml=".5rem">Jsem si vědom/a storno poplatku v případě zrušení přihlášky po jejím odeslání.</FormLabel>
            </Flex>
            <FormErrorMessage>{question1Warning.text}</FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={question2Warning.show}>
            <Flex alignItems="center" >
                <Checkbox backgroundColor="white" colorScheme="blue" isChecked={question2} onChange={() => setQuestion2(!question2)} />
                <FormLabel ml=".5rem">Odesláním této závazné přihlášky souhlasím s tím, že případný přebytek v rozpočtu bude využit na nákup dalšího vybavení nebo na rozvoj střediska a že fotografie pořízené na táboře mohou být zveřejněny na webových stránkách střediska.</FormLabel>
            </Flex>
            <FormErrorMessage>{question2Warning.text}</FormErrorMessage>
        </FormControl>
        <FormControl mt="1rem" isRequired isInvalid={question3Warning.show}>
            <Flex alignItems="start">
                <Checkbox backgroundColor="white" colorScheme="blue" isChecked={question3} onChange={() => setQuestion3(!question3)} />
                <FormLabel ml=".5rem">Odesláním této závazné přihlášky souhlasím s možností přepravy dítěte automobilem v případě nutnosti.</FormLabel>
            </Flex>
            <FormErrorMessage>{question3Warning.text}</FormErrorMessage>
        </FormControl>

        <Center>
            <Button m="2rem" fontFamily="skautbold, sans-serif" border="solid 1px" borderColor="blackAlpha.500" fontSize={width< 250 ? "x-small" : width < 300 ? "small" : "medium"} onClick={sendData}>Poslat přihlášku</Button>
        </Center>

        <Link position="absolute" right="1rem" bottom="1rem" textDecor="underline" _hover={{textDecor: "none"}}><RouteLink to="/admin">Admin</RouteLink></Link>

    </Box>
}